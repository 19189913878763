import filter from "./filter.js";
import store from "store";
import Cookies from "js-cookie";
import { Message } from "iview";
import storageConstant from "@/common/constants/local-storage.constant";
import appConstant from "@/common/constants/app.constant";
import { TOTP } from "totp-generator";
import debounce from "lodash/debounce";
const md5 = require("md5");
import dayjs from "dayjs";
import { jsonrepair } from "jsonrepair";
import * as Sentry from "@sentry/vue";
import axios from "axios";
import { isEmpty } from "lodash";
import DOMPurify from "dompurify";

function formatDay(day) {
  const week = {
    0: "Mon",
    1: "Tue",
    2: "Wed",
    3: "Thu",
    4: "Fri",
    5: "Sat",
    6: "Sun",
  };
  return week[day];
}

function formatMonth(month) {
  const monthes = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec",
  };
  return monthes[month];
}
var chatTime = function (dateable) {
  var date = new Date(dateable);
  var nowdate = new Date();
  var diffmilisec = nowdate - date;
  if (diffmilisec < 1000 * 60 * 60 * 12) {
    return time_12(dateable);
  } else {
    return relativeDay(dateable) + ", " + time_12(dateable);
  }
};
var relativeTime = function (dateable) {
  var date = new Date(dateable);
  var nowdate = new Date();
  var diffmilisec = nowdate - date;

  if (diffmilisec < 0) {
    var randomSeconds = Math.floor(Math.random() * 59);
    return randomSeconds + " second(s) ago";
  } else if (diffmilisec < 1000 * 60 * 60 * 12) {
    if (diffmilisec < 1000 * 60) {
      return parseInt(diffmilisec / 1000) + " second(s) ago";
    } else if (diffmilisec < 1000 * 60 * 60) {
      return parseInt(diffmilisec / 1000 / 60) + " minute(s) ago";
    } else {
      return parseInt(diffmilisec / 1000 / 60 / 60) + " hour(s) ago";
    }
  } else {
    return relativeDay(dateable) + ", " + time_12(dateable);
  }
};

// return the relative Day or absolutely day if before 'Yesterday'
var relativeDay = function (dateable) {
  var date = new Date(dateable);
  var nowdate = new Date();
  var yesterday = new Date(new Date().getTime() - 1000 * 60 * 60 * 24);

  if (new Date(date).setHours(0, 0, 0, 0) === new Date(nowdate).setHours(0, 0, 0, 0)) {
    return "Today";
  } else if (new Date(date).setHours(0, 0, 0, 0) === new Date(yesterday).setHours(0, 0, 0, 0)) {
    return "Yesterday";
  } else {
    return `${date.getDate()} ${formatMonth(date.getMonth())} ${date.getFullYear()}`;
  }
};
var time_12 = function (dateable) {
  var date = new Date(dateable);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + ampm;
  return strTime;
};
var relativeDayToDate = function (relativeDay) {
  var splited = relativeDay.split(", ");
  var day;
  switch (splited[0]) {
    case "Today":
      day = `${new Date().getDate()} ${formatMonth(new Date().getMonth())} ${new Date().getFullYear()}`;
      break;
    case "Yesterday":
      day = `${new Date(new Date().getTime() - 1000 * 60 * 60 * 24).getDate()} ${formatMonth(
        new Date(new Date().getTime() - 1000 * 60 * 60 * 24).getMonth(),
      )} ${new Date(new Date().getTime() - 1000 * 60 * 60 * 24).getFullYear()}`;
      break;
    default:
      day = splited[0];
  }
  var time;
  var regtime = /(\d+):(\d+)/.exec(splited[1]);
  if (/pm/.exec(splited[1])) {
    time = parseInt(regtime[1]) + 12 + ":" + regtime[2];
  } else {
    time = regtime[1] + ":" + regtime[2];
  }
  return new Date(`${day}, ${time}`);
};

var storeInLocalStorage = {
  set: function (key, val) {
    localStorage.setItem(key, JSON.stringify(val));
  },
  get: function (key) {
    try {
      const dataStr = localStorage.getItem(key);

      // Add debug for issue "system suddenly logged out"
      if (!window.location.href.includes("login") && key === storageConstant.TOKEN && !dataStr) {
        console.log({ k: "tk", val: dataStr, rb: localStorage.getItem(storageConstant.REMEMBER) });
        Sentry.captureMessage("Auth Error");
      }

      // Migrate old data format {val: {}, exp: 0, time: 1234}
      if (!dataStr) return null;

      const data = JSON.parse(dataStr);

      const dataProperties = Object.keys(data);
      if (dataProperties && dataProperties.includes("val") && dataProperties.includes("time")) return data.val;

      return data;
    } catch (ex) {
      return null;
    }
  },
};

var throttle = function (fn, delay) {
  var timer = null;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};

var randomAvatar = function () {
  var index = Math.floor(Math.random() * 12 + 1);
  return `/static/img/avatar-${index}.png`;
};

var userAvatar = function (id) {
  var index = (id % 12) + 1;
  return `/static/img/avatar-${index}.png`;
};

var getToken = function () {
  const remember = storeInLocalStorage.get(storageConstant.REMEMBER);
  const token = remember ? storeInLocalStorage.get(storageConstant.TOKEN) : Cookies.get(storageConstant.TOKEN);

  return token;
};

var getMfaCode = function () {
  const debug_st_mfa = localStorage.getItem(storageConstant.MFA_CODE);
  const debug_ck_mfa = Cookies.get(storageConstant.MFA_CODE);
  const remember = storeInLocalStorage.get(storageConstant.REMEMBER);
  const mfa_code = remember ? storeInLocalStorage.get(storageConstant.MFA_CODE) : Cookies.get(storageConstant.MFA_CODE);

  return mfa_code;
};

var reSetAuth = function () {
  const remember = storeInLocalStorage.get(storageConstant.REMEMBER);

  if (remember === true) {
    let currentToken = storeInLocalStorage.get(storageConstant.TOKEN);
    currentToken && storeInLocalStorage.set(storageConstant.TOKEN, currentToken);

    if (!currentToken) {
      console.log({ currentTK: currentToken, remember });
      Sentry.captureMessage("Auth Error");
    }
  }
};

const updateUI = {};
const getDataFromSw = (key, callback) => {
  updateUI[key] = callback;
};
navigator.serviceWorker &&
  navigator.serviceWorker.addEventListener("message", ({ data }) => {
    for (let key in data) {
      if (updateUI[key]) {
        if (data[key].status && data[key].status === 403 && data[key].hasOfflineData) {
          Message.error("You don't have permission to perform this action");
          window.location.hash = "#/mails";
          return;
        }
        if (data[key].status && data[key].status >= 400 && data[key].hasOfflineData) {
          // Message.error("Network error!");
          return;
        }
        if (!data[key].status) {
          updateUI[key](data[key]);
          key !== "online" && delete updateUI[key];
        }
      }
    }
  });

const sendMessageToSw = (message) => {
  const msg_chan = new MessageChannel();
  if (navigator.serviceWorker && navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage(message, [msg_chan.port2]);
  }
};

const filterTitle = (subject) => {
  if (!subject) return "";
  let result;
  ["Re: ", "RE: ", "Fw: ", "FW: ", "Fwd: ", "FWD: "].forEach((e) => {
    if (subject.startsWith(e)) {
      result = subject.replace(e, "");
    }
  });
  if (result) {
    return result;
  } else {
    return subject;
  }
};

const removeSearchHtml = (html) => {
  if (!html) return "";
  return html
    .replace(/<\/?span.*?>/g, "")
    .replace(/&gt;/gi, ">")
    .replace(/&lt;/gi, "<");
};

const escapeHtmlTags = (html) => {
  if (!html) return "";
  return html.replace(/>/g, "&gt;").replace(/</g, "&lt;");
};

const sanitizeHtml = (html) => {
  if (!html) return "";
  let element = document.createElement("div");
  element.innerHTML = html;
  return element.innerText;
};

const plainTextToHtml = (text) => {
  if (/\t/.test(text)) {
    return `<pre>${text}</pre>`;
  }

  return text.replace(/\r\n/g, "<br>").replace(/\r/g, "<br>").replace(/\n/g, "<br>");
};

const html2text = (html) => {
  if (!html) return "";
  let element = document.createElement("div");
  element.innerHTML = html;
  let textFormatted = getText(element);
  textFormatted = textFormatted.replace(/<[^>]+>/gi, "");
  return textFormatted.split("||br||").join("<br>");
};

const getText = (n) => {
  var rv = "";
  if (n.nodeType == 3) {
    rv = n.nodeValue;
  } else {
    if (n.childNodes && n.childNodes.length > 0) {
      for (var i = 0; i < n.childNodes.length; i++) {
        rv += getText(n.childNodes[i]);
      }
    }
    var d = getComputedStyle(n).getPropertyValue("display");
    if (
      d.match(/^block/) ||
      d.match(/list/) ||
      n.tagName === "BR" ||
      n.tagName === "TR" ||
      n.tagName === "DIV" ||
      n.tagName === "P"
    ) {
      rv = rv + "||br||";
    }
    if (n.tagName === "UL" || n.tagName === "OL") {
      rv = "||br||" + rv;
    }
  }
  rv = rv.replace(/<[^>]+>/gi, "");
  return rv;
};

const filterReaders = (readers) => {
  if (!readers) return [];

  const filteredNames = [];
  const filteredReaders = [];

  readers.forEach((reader) => {
    if (!filteredNames.includes(reader.name)) {
      filteredReaders.push(reader);
      filteredNames.push(reader.name);
    }
  });

  return filteredReaders;
};
const highlightSearchKey = (searchKey, source) => {
  if (!(searchKey && source)) return source;
  const regx = new RegExp(searchKey, "gi");
  return source.replace(regx, (r) => {
    return r ? `<mark>${r}</mark>` : "";
  });
};

const isNullOrUndefined = (obj) => {
  return obj == undefined || obj == null;
};

const deleteCharater = (el, callback) => {
  el.addEventListener("keydown", function (e) {
    if (e.key == "Delete") {
      const text = e.target.value;
      const cursorPosition = e.target.selectionStart;
      const isSelecting = e.target.selectionStart != e.target.selectionEnd;
      const newText = isSelecting
        ? text.substring(0, cursorPosition) + text.substring(e.target.selectionEnd, text.length)
        : text.substring(0, cursorPosition) + text.substring(cursorPosition + 1, text.length);
      e.target.value = newText;
      e.target.selectionStart = e.target.selectionEnd = cursorPosition;
      callback && callback(newText);
    }
  });
};

const scrollToLoadMore = (el, options) => {
  el.addEventListener(
    "scroll",
    debounce(function (e) {
      const scrollHeight = e.target.scrollHeight;
      const clientHeight = e.target.clientHeight;
      const scrollTop = e.target.scrollTop;

      !options &&
        (options = {
          bottomDistance: 0,
          topDistance: 0,
        });

      const atTop = scrollTop <= options.topDistance;
      atTop && options.scrollToTopCallback && options.scrollToTopCallback();

      const atBottom =
        scrollHeight != clientHeight && scrollHeight - scrollTop - clientHeight <= options.bottomDistance;
      atBottom && options.scrollToBottomCallback && options.scrollToBottomCallback();
    }, 300),
  );
};

const isEmptyObject = (obj) => {
  if (!obj) return false;
  return Object.entries(obj).length == 0 && obj.constructor == Object;
};

const getUserId = () => {
  const user = storeInLocalStorage.get(storageConstant.USER);
  return user && user.user && user.user.id;
};

const is2FAEnabled = () => {
  return storeInLocalStorage.get(storageConstant.TWO_FA_ENABLED);
};

const getTotpToken = () => {
  const userId = getUserId();
  const mfaBinaryCode = getMfaCode();
  const mfaCode = mfaBinaryCode && !isEmptyObject(mfaBinaryCode) && atob(mfaBinaryCode);
  const totpToken = !isEmpty(mfaCode) ? TOTP.generate(mfaCode)?.otp : null;
  const totpTokenMD5 = totpToken && totpToken != "" && md5(`${totpToken}_${userId}`);
  return totpTokenMD5;
};

const functionBus = {};
const routeBasedEmails = {};
const routeBasedEmailKeys = [];

const updateAllCachedEmails = ({ ids, changedProps, remove, includeKeys }) => {
  Object.keys(routeBasedEmails).forEach((key) => {
    if (includeKeys && !includeKeys.includes(key)) return;
    if (!ids || !Array.isArray(ids)) return;

    const emailMap = routeBasedEmails[key].emailMap;

    ids.forEach((id) => {
      if (emailMap.has(id)) {
        if (!remove && changedProps) {
          let email = emailMap.get(id);
          Object.assign(email, changedProps(email)); // Update UI automatically
          emailMap.set(id, email);
          return;
        }
        if (remove) {
          emailMap.delete(id);
          changedProps && Object.assign(routeBasedEmails[key], changedProps(routeBasedEmails[key]));
          return;
        }
      }
    });
  });
};

const resetCachedEmails = (pattern) => {
  const keys = Object.keys(routeBasedEmails).filter((key) => key.includes(pattern));
  keys.forEach((key) => {
    delete routeBasedEmails[key];
  });
};

const equalListElements = (lArray, rArray) => {
  if (lArray.length != rArray.length) return false;

  for (let i = 0; i < lArray.length; i++) {
    if (lArray[i] != rArray[i]) return false;
  }

  return true;
};

const removeLastElement = (array) => {
  array.pop();
  return array;
};

const openWindow = (path, isFilePreview = false, windowTitle = "") => {
  let options = localStorage.getItem(storageConstant.WINDOW_SIZE);
  options = options && JSON.parse(options);
  const width = options && options.width && options.width > 1100 ? options.width : 1100;
  const height = options && options.height && options.height > 700 ? options.height : 700;
  const left = options && !isNaN(options.left) ? options.left : (screen.width - width) / 2;
  const top = options && !isNaN(options.top) ? options.top : (screen.height - height) / 2;

  const params = [
    "height=" + height,
    "width=" + width,
    "location=no",
    "top=" + top,
    "left=" + left,
    "fullscreen=no",
  ].join(",");
  const url = (path || "").startsWith("http") ? path : window.location.origin + "/#/" + path;
  const popup = window.open(isFilePreview ? "" : url, "_blank", params);
  popup && popup.moveTo(left, top);
  popup &&
    popup.addEventListener("unload", (e) => {
      storeWindowSize(popup);
    });

  if (!isFilePreview) return popup;

  const primaryColor = getComputedStyle(document.documentElement).getPropertyValue("--primary-color");

  popup.document.write(`<html>
    <head>
      <title>${windowTitle}</title>
      <meta name="theme-color" content="${primaryColor || "#063160"}">
    </head>
    <body style="margin: 0; padding: 0">
      <iframe src="${url}" style="width: 100%; height: 100%; margin: 0; padding: 0; border: none;"></iframe>
    </body>
  </html>`);

  return popup;
};

const storeWindowSize = (targetWindow) => {
  localStorage.setItem(
    storageConstant.WINDOW_SIZE,
    JSON.stringify({
      width: targetWindow.outerWidth,
      height: targetWindow.outerHeight,
      top: targetWindow.screenTop,
      left: targetWindow.screenLeft,
    }),
  );
};

const selectText = (containerEl) => {
  if (document.selection) {
    // IE
    let range = document.body.createTextRange();
    range.moveToElementText(containerEl);
    range.select();
  } else if (window.getSelection) {
    let range = document.createRange();
    range.selectNode(containerEl);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  }
};

const getInitialName = (fullName, maximumLetters = 2) => {
  if (!fullName) return "-";

  const words = fullName.split(" ");
  if (!words || words.length == 0) return "-";

  if (words.length == 1) return words[0].substr(0, maximumLetters);

  let initialName = "";
  for (let word of words) {
    word && (initialName += word.trim().substr(0, 1));
    if (initialName.length == maximumLetters) break;
  }
  return initialName;
};

const changeTheme = (themeName) => {
  const themes = appConstant.themeNames;
  const themeIds = Object.keys(themes);
  if (themeIds.findIndex((id) => themes[id] == themeName) == -1) themeName = appConstant.themeNames.ORIGINAL;

  let htmlElement = document.documentElement;
  htmlElement.setAttribute("theme", themeName);
  htmlElement.setAttribute("data-theme", themeName);
};

const convertHexToRGBA = (hex, opacity) => {
  hex = hex.replace("#", "");
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  let result = "rgba(" + r + "," + g + "," + b + "," + opacity + ")";
  return result;
};

const compare2Object = (obj, source, exceptProperties = [], options = { excludeEmptyProperties: false }) => {
  if ((!obj && source) || (obj && !source)) return false;

  if (!obj && !source) return true;

  let keys = Object.keys(obj).filter((k) => exceptProperties.findIndex((p) => p == k) == -1);
  let sourceKeys = Object.keys(source).filter((k) => exceptProperties.findIndex((p) => p == k) == -1);

  if (options.excludeEmptyProperties) {
    keys = keys.filter((k) => !isNullOrUndefined(obj[k]) && obj[k] != "");
    sourceKeys = sourceKeys.filter((k) => !isNullOrUndefined(source[k]) && source[k] != "");
  }

  if (keys.length != sourceKeys.length) return false;

  for (let k of keys) {
    if (
      !source.hasOwnProperty(k) ||
      typeof source[k] != typeof obj[k] ||
      (typeof obj[k] !== "object" && source[k] != obj[k]) ||
      (typeof obj[k] == "object" && !compare2Object(obj[k], source[k], exceptProperties, options))
    )
      return false;
  }
  return true;
};

const getTenantID = () => {
  const user = storeInLocalStorage.get(storageConstant.USER);
  return user && user.company && user.company.tenant_id;
};

const appendTenantID = (baseURL = "", tenant_id, isWebsocket) => {
  let protocol = "";
  isWebsocket && (protocol = (baseURL.includes("wss://") && "wss://") || "ws://");
  !isWebsocket && (protocol = (baseURL.includes("https://") && "https://") || "http://");
  baseURL = (!baseURL.includes(protocol) && protocol + baseURL) || baseURL;
  const tenantId = tenant_id || getTenantID();
  return (
    (tenantId &&
      !baseURL.includes(`${protocol}${tenantId}.`) &&
      baseURL.replace(protocol, `${protocol}${tenantId}.`)) ||
    baseURL
  );
};

const distinctArray = (array, key) => {
  if (!array || array.length == 0) return array;
  return array.filter((item, index, arr) => array.findIndex((a) => a[key] === item[key]) === index);
};

const copyToClipboard = async (str) => {
  if (navigator.clipboard && navigator.clipboard.write) {
    try {
      const blob = new Blob([str], { type: "text/plain" });
      const clipboardItem = new ClipboardItem({ "text/plain": blob });
      await navigator.clipboard.write([clipboardItem]);
      return;
    } catch (err) {
      console.warn("Clipboard API failed, falling back...", err);
    }
  }

  const el = document.createElement("textarea");
  el.value = str;

  el.style.position = "fixed";
  el.style.opacity = "0";
  el.style.pointerEvents = "none";
  el.style.boxSizing = "content-box";
  el.style.border = "none";
  document.body.appendChild(el);
  el.select();

  try {
    document.execCommand("copy");
  } catch (err) {
    console.error("Copy failed: ", err);
  }

  document.body.removeChild(el);
};

const copyHtmlToClipboard = (html) => {
  const el = document.createElement("div");
  el.style.height = 0;
  el.style.fontSize = "13px";
  el.style.color = "black";
  el.setAttribute("tabindex", "0");
  document.body.appendChild(el);
  el.innerHTML = html;
  el.focus();
  selectText(el);
  document.execCommand("copy");
  document.body.removeChild(el);
};

const removeSpecialCharacters = (str) => {
  return str.replace(/[!@#$%^&*(),.?":{}|<>+\-=\\[\]]/g, "");
};

const escapeSpecialCharacters = (str) => {
  return str.replace(/[!@#$%^&*(),.?":{}|<>+\-=\\[\]]/g, "\\$&");
};

// Check if service worker activated
const isSWActivated = () => {
  return (
    navigator.serviceWorker &&
    navigator.serviceWorker.controller &&
    navigator.serviceWorker.controller.state == "activated"
  );
};

const horizontalScroll = (el) => {
  if (!el || el.onwheel) return;
  el.onwheel = function (e) {
    if (e.deltaY > 0) el.scrollLeft += 100;
    else el.scrollLeft -= 100;
  };
};

const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const registerGoogleAnaytic = () => {
  let gtagID;
  const savedBaseUrl = localStorage.getItem(storageConstant.BASE_URL) || "";
  switch (savedBaseUrl) {
    case "mti-legacy.sg.kpler.com":
      gtagID = "G-J1Y8Q1KDX8";
      break;
    case "mti-legacy.kpler.com":
      gtagID = "G-L75163LH09";
      break;
    // case /sapious\.com/i.test(hostname):
    //   gtagID = 'G-9TE8HJ690F';
    //   break;
    default:
      gtagID = "G-V05681LVSF";
      break;
  }
  const d = document;
  const s = d.createElement("script");
  s.type = "text/javascript";
  s.async = true;
  s.src = `https://www.googletagmanager.com/gtag/js?id=${gtagID}`;
  d.getElementsByTagName("head")[0].appendChild(s);

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    /* eslint-disable-next-line */
    dataLayer.push(arguments);
  }
  gtag("js", new Date());
  gtag("config", gtagID);
};

const dateWithoutTime = (date) => {
  return new Date(new Date(date || new Date()).toDateString());
};

const updateServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    const registrations = await navigator.serviceWorker.getRegistrations();
    await Promise.all(registrations.map((registration) => registration.update()));
    setTimeout(() => {
      updateServiceWorker();
    }, 60 * 60 * 1000);
  }
};

const convertToKebabCase = (str) => {
  return (
    str &&
    str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map((x) => x.toLowerCase())
      .join("-")
  );
};

const getDayIndexOfMonth = (d) => {
  let dates = [];
  const day = d.getDay();
  const date = d.getDate();
  const month = d.getMonth();
  const year = d.getFullYear();
  d.setDate(1);
  d.setHours(0);
  while (d.getMonth() == month && d.getFullYear() == year) {
    if (d.getDay() == day) {
      dates.push(new Date(d.getTime()));
      d.setDate(d.getDate() + 7);
    } else d.setDate(d.getDate() + 1);
  }
  return dates.findIndex((d) => d.getDate() == date);
};

const getFileExtentsion = (filename) => {
  if (!filename) return "";
  const parts = filename.split(".");
  return parts[parts.length - 1];
};

const removeUnexpectedTags = (container, tags) => {
  tags.forEach((tag) => {
    [...container.querySelectorAll(tag)].forEach((el) => {
      el.remove();
    });
  });
};

const bytesToSize = (bytes) => {
  var sizes = ["B", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

const extractDate = (fullDate) => {
  return fullDate ? dayjs(fullDate).format("YYYY-MM-DD") : null;
};

const extractTime = (fullDate) => {
  return fullDate ? dayjs(fullDate).format("HH:mm") : null;
};

const getSelectionText = () => {
  var text = "";
  if (window.getSelection) {
    text = window.getSelection().toString();
  } else if (document.selection && document.selection.type != "Control") {
    text = document.selection.createRange().text;
  }
  return text;
};

const validateJSON = (json) => {
  try {
    JSON.parse(json);
  } catch (e) {
    return jsonrepair(json);
  }
  return json;
};

const downloadFile = (url, fileName) => {
  const element = document.createElement("a");

  element.href = url;
  element.download = fileName;
  element.style.display = "none";

  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

const downloadFileAxios = async (url, fileName, options) => {
  await axios
    .get(url, { ...options, responseType: "blob" })
    .then((response) => {
      const type = response.data.type;
      const blob = new Blob([response.data], { type: type });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(link.href);
    })
    .catch(async (error) => {
      console.error("Axios download failed:", error);
      // Fallback to direct download method
      downloadFile(url, fileName);
      await new Promise((resolve, _) => {
        setTimeout(() => {
          resolve();
        }, 1000);
      });
    });
};

const parseUrl = (url = "") => {
  const [origin = "", queryString = ""] = url.split("?");

  const queryParams = queryString ? queryString.split("&") : [];
  const mapping = {};

  queryParams.forEach((q) => {
    const [key, value] = q.split("=");
    mapping[key] = value;
  });

  const generateUrl = () => {
    let newQueryString = Object.entries(mapping)
      .map(([key, value]) => {
        return `${key}=${value}`;
      })
      .join("&");

    newQueryString = newQueryString ? `?${newQueryString}` : "";

    return `${origin}${newQueryString}`;
  };

  return {
    addQueryParam: (key, value) => {
      mapping[key] = value;
      return generateUrl();
    },
    removeQueryParam: (key) => {
      delete mapping[key];
      return generateUrl();
    },
  };
};

const getBaseImageURL = (url) => {
  if (!url) return "";

  const strs = url.split("?");
  if (strs && strs.length > 0) return strs[0];

  return "";
};

export class LRUCache {
  constructor(max = 10) {
    this.max = max;
    this.cache = new Map();
  }

  has(key) {
    return this.cache.has(key);
  }

  get(key) {
    let item = this.cache.get(key);
    if (item !== undefined) {
      this.cache.delete(key);
      this.cache.set(key, item);
    }
    return item === undefined ? -1 : item;
  }

  put(key, val) {
    if (this.cache.has(key)) this.cache.delete(key);
    else if (this.cache.size == this.max) this.cache.delete(this.first());
    this.cache.set(key, val);
  }

  first() {
    return this.cache.keys().next().value;
  }
}

export { filter };

export const insertSpaceBetweenUpper = (str) => {
  if (!str) return "";
  return str.replace(/([a-z])([A-Z])/g, "$1 $2");
};

export const concatWithoutDuplicates = (arr1, arr2) => {
  let set1 = new Set(arr1);
  let set2 = new Set(arr2);
  let combinedSet = new Set([...set1, ...set2]);
  let resultArray = Array.from(combinedSet);
  return resultArray;
};

export const isGUID = (value) => {
  var guidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return guidPattern.test(value);
};

export const exactSort = (array, str, property) => {
  return array.sort((a, b) => {
    const currentString = property && a?.[property] ? a?.[property] : a;
    const nextString = property && b?.[property] ? b?.[property] : b;
    if (currentString === str) return -1;
    if (nextString === str) return 1;
    if (currentString.startsWith(str) && !nextString.startsWith(str)) return -1;
    if (!currentString.startsWith(str) && nextString.startsWith(str)) return 1;

    const aContains = currentString.includes(str);
    const bContains = nextString.includes(str);

    if (aContains && bContains) {
      return currentString.localeCompare(nextString);
    }

    if (aContains) return -1;
    if (bContains) return 1;

    return 0;
  });
};

export const validateBaseUrl = () => {
  const savedBaseUrl = localStorage.getItem(storageConstant.BASE_URL) || "";
  const whiteListEndpoint = ["chartdesk.net", "chartdesk.de", "chartdesk.cn"];
  return !isEmpty(savedBaseUrl) && whiteListEndpoint.some((url) => savedBaseUrl.includes(url));
};

export const getDefaultBaseURL = () => {
  let baseUrl = "staging-api.chartdesk.net";

  if (location.host.includes("chartdesk.pro") && !location.host.includes("staging")) {
    baseUrl = "prod-api.kong.chartdesk.net";
  }

  if (location.host.includes("chartdesk.de") || location.host.includes("inbox.marinetraffic.com")) {
    baseUrl = "prod-api.kong.chartdesk.de";
  }
  return baseUrl;
};

export const sanitizeObject = (data) => {
  if (!data || typeof data !== "object") return data; // Return non-objects as-is

  const sanitizedObj = Array.isArray(data) ? [] : {};

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const value = data[key];

      if (typeof value === "string") {
        sanitizedObj[key] = DOMPurify.sanitize(value);
      } else if (typeof value === "object" && value !== null) {
        sanitizedObj[key] = sanitizeObject(value); // Recursively sanitize nested objects
      } else {
        sanitizedObj[key] = value; // Copy other types as-is
      }
    }
  }

  return sanitizedObj;
};

export const decodeJWT = (token) => {
  const base64Url = token.split(".")[1]; // Get the payload part
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/"); // Convert to Base64
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join(""),
  );
  return JSON.parse(jsonPayload);
};

export const convertGoogleAuthUrl = (url) => {
  const urlMapping = {
    "mti-legacy.sg.kpler.com": "prod-api.chartdesk.net",
    "mti-legacy.kpler.com": "prod-api.chartdesk.de",
    "mti.sg.dev.galil.io": "staging-api.chartdesk.net",
    "mti.kpler.com": "prod-api-iac.chartdesk.de",
  };
  const auth0Url = Object.keys(urlMapping).find((key) => url.includes(key));
  if (auth0Url) return url.replace(auth0Url, urlMapping[auth0Url]);
  return url;
};

export default {
  filter,
  relativeDay,
  relativeDayToDate,
  relativeTime,
  chatTime,
  time_12,
  formatDay,
  storeInLocalStorage,
  throttle,
  randomAvatar,
  userAvatar,
  reSetAuth,
  getToken,
  getMfaCode,
  getDataFromSw,
  sendMessageToSw,
  filterTitle,
  html2text,
  getText,
  sanitizeHtml,
  filterReaders,
  removeSearchHtml,
  escapeHtmlTags,
  highlightSearchKey,
  isNullOrUndefined,
  deleteCharater,
  scrollToLoadMore,
  isEmptyObject,
  getUserId,
  is2FAEnabled,
  getTotpToken,
  functionBus,
  routeBasedEmails,
  routeBasedEmailKeys,
  updateAllCachedEmails,
  resetCachedEmails,
  equalListElements,
  removeLastElement,
  openWindow,
  selectText,
  getInitialName,
  changeTheme,
  convertHexToRGBA,
  compare2Object,
  storeWindowSize,
  distinctArray,
  copyToClipboard,
  copyHtmlToClipboard,
  removeSpecialCharacters,
  escapeSpecialCharacters,
  isSWActivated,
  appendTenantID,
  getTenantID,
  horizontalScroll,
  capitalizeFirstLetter,
  registerGoogleAnaytic,
  dateWithoutTime,
  plainTextToHtml,
  updateServiceWorker,
  convertToKebabCase,
  getDayIndexOfMonth,
  getFileExtentsion,
  removeUnexpectedTags,
  bytesToSize,
  extractDate,
  extractTime,
  getSelectionText,
  validateJSON,
  downloadFile,
  parseUrl,
  getBaseImageURL,
  downloadFileAxios,
  exactSort,
  validateBaseUrl,
  getDefaultBaseURL,
  sanitizeObject,
  convertGoogleAuthUrl,
};
