import { render, staticRenderFns } from "./VerticalMenu.vue?vue&type=template&id=58ee05b5&scoped=true&"
import script from "./VerticalMenu.vue?vue&type=script&lang=js&"
export * from "./VerticalMenu.vue?vue&type=script&lang=js&"
import style0 from "./VerticalMenu.vue?vue&type=style&index=0&id=58ee05b5&prod&scoped=true&lang=scss&"
import style1 from "./VerticalMenu.vue?vue&type=style&index=1&id=58ee05b5&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58ee05b5",
  null
  
)

export default component.exports