let config = require("./staging.config");

switch (location.hostname) {
  case "inbox.marinetraffic.com":
  case "sg.chartdesk.pro":
  case "web.chartdesk.pro":
  case "web.chartdesk.de":
  case "beta.chartdesk.pro":
  case "beta.chartdesk.de":
    config = require("./prod.config");
    break;
  default:
    config = require("./staging.config");
}

export const { auth0_domain, auth0_clientId, common_services_url, GROWTHBOOK_API_HOST, GROWTHBOOK_CLIENT_KEY } = config;
