<template>
  <div class="container-wrapper table-wrapper" v-bind:class="{ 'padding-none': viewMultiple }">
    <section class="shipping container" v-if="!viewMultiple">
      <!--loading-->
      <div v-if="tableLoading && !$route.query.detail_Id" class="ship-loading"></div>
      <!--title-->
      <header class="headerTitle" :style="{ opacity: tableLoading ? 0.3 : 1 }">
        <div class="leftSide">
          <div>
            <Select
              v-if="isEnablePositionView && !$route.query.search_key && !$route.query.name"
              v-model="view"
              class="viewSelect select-no-border"
              size="small"
              @on-change="viewChange"
            >
              <Option value="ship">Ship View</Option>
              <Option value="position">Position View</Option>
            </Select>
            <Select
              v-if="!$route.query.search_key || !$route.query.search"
              v-model="flag"
              class="select-no-border"
              size="small"
              @on-change="optionChange"
            >
              <Option value="all">All</Option>
              <Option value="Dry/Bulk">Dry/Bulk</Option>
              <Option value="Tankers">Tankers</Option>
            </Select>
            <Tooltip class="Ship_AIS" content="AIS" v-if="$route.query.id">
              <a @click="viewAisMultiple(true)" class="actionable-icon">
                <i class="iconfont-ship-ais" :class="{ active: checkGroup.length }" />
              </a>
            </Tooltip>
          </div>
        </div>
        <div class="rightSide list">
          <div class="edit_advance">
            <!--handle advance-->
            <p class="edit_advance_text" v-if="$route.query.search" @click="openSaveAdvance">Save this search</p>
            <span class="separateLine" v-if="$route.query.search"></span>
            <p class="edit_advance_text" v-if="$route.query.search" @click="editAdvance">Refine this search</p>
          </div>
          <div class="handlePage">
            <!--page 上下页-->
            <div class="pagination first-page" @click="skipPage(1)" :class="{ unActive: currentPage == 1 }">
              <Icon type="md-skip-backward" />
            </div>
            <Page
              :total="totalNum"
              :current="currentPage"
              simple
              :page-size="pageSize"
              @on-change="changePage"
              class="page"
            ></Page>
            <div class="pagination last-page" @click="skipPage(2)" :class="{ unActive: currentPage == totalPages }">
              <Icon type="md-skip-forward" />
            </div>
            <Dropdown v-if="$route.query.id" placement="bottom-end">
              <i class="iconfont-more-vertical"></i>
              <Dropdown-menu slot="list">
                <Dropdown-item @click.native="gotoDetail" :class="{ unActive: checkGroup.length == 0 }">
                  AIS
                </Dropdown-item>
                <Dropdown-item @click.native="removeShipsFromGroup" :class="{ unActive: checkGroup.length == 0 }">
                  Remove from fleet
                </Dropdown-item>
                <Dropdown-item v-if="p_edit_group" @click.native="editFleet">Edit fleet</Dropdown-item>
                <Dropdown-item @click.native="showFleetDetail">Fleet details</Dropdown-item>
                <Dropdown-item v-if="p_delete_group" @click.native="confirmShow = true">Delete fleet</Dropdown-item>
              </Dropdown-menu>
            </Dropdown>
          </div>
        </div>
      </header>
      <div class="main-content-wrapper" :style="{ opacity: tableLoading ? 0.3 : 1 }">
        <!-------------------------ship table------------------------>
        <div class="contain-wrapper" ref="wrapper">
          <div class="table-contain ivu-table-with-fixed-top" :style="tableHeight">
            <!--table header-->
            <div class="ivu-table-header">
              <table class="ui table shipping-table">
                <thead :class="{ fixedWidth: tableData.length > 8 }">
                  <tr>
                    <th class="checkBox">
                      <Checkbox :value="isSelectAll" @on-change="selectAll"></Checkbox>
                      <Tooltip
                        v-if="checkGroup.length > 0"
                        content="Clear selection"
                        :transfer="true"
                        placement="top-start"
                        class="selected-count"
                      >
                        <p @click="clearCheck">{{ checkGroup.length }} selected</p>
                      </Tooltip>
                    </th>
                    <th class="IMO">{{ checkGroup.length > 0 ? "" : "IMO" }}</th>
                    <th class="IMO">MMSI</th>
                    <th class="name">Name</th>
                    <th class="clickable bold build" @click="toggleSort('build_year')">
                      Year
                      <!--sort-->
                      <span :class="{ active: sortField === 'build_year' }">
                        <Icon
                          type="arrow-down-b"
                          v-if="sortField !== 'build_year' || (sortField === 'build_year' && sortMethod === 'desc')"
                        ></Icon>
                        <Icon type="md-arrow-dropup" v-if="sortField === 'build_year' && sortMethod === 'asc'"></Icon>
                      </span>
                    </th>
                    <th class="limit">DWT</th>
                    <th class="limit">Ex Name</th>
                    <th class="limit">Call Sign</th>
                    <th class="limit">GT</th>
                    <th class="limit">LOA</th>
                    <th class="limit">BEAM</th>
                    <th class="limit">Draught</th>
                    <th class="tags">
                      <div class="tags-cell">
                        <span>Tags</span>
                        <span v-if="showFilter == false">
                          <i class="iconfont-filter" @click="toggleFilter"></i>
                        </span>
                        <span v-else @click="toggleFilter" class="clearFilter">Clear all</span>
                      </div>
                    </th>
                  </tr>
                  <!--header Filter-->
                  <tr v-if="showFilter" class="filterHeads" @keydown.enter="enterFilter(undefined)">
                    <td class="checkBox"></td>
                    <td class="IMO">
                      <div><input type="text" placeholder="IMO" v-model="filter.lrimo" /></div>
                    </td>
                    <td class="IMO">
                      <div><input type="text" placeholder="MMSI" v-model="filter.mmsi" /></div>
                    </td>
                    <td class="name">
                      <div><input type="text" placeholder="Name" v-model="filter.name" /></div>
                    </td>
                    <td class="build" style="padding: 0">
                      <div>
                        <DatePicker
                          v-model="filter.year_of_build"
                          @on-change="enterFilter"
                          @on-clear="clearDate"
                          type="year"
                          placeholder="Year"
                          class="filter-year"
                        ></DatePicker>
                      </div>
                    </td>
                    <td class="limit">
                      <div><input type="text" v-model.trim="filter.deadweight" placeholder="DWT" /></div>
                    </td>
                    <td class="limit">
                      <div><input type="text" v-model.trim="filter.ex_name" placeholder="Ex Name" /></div>
                    </td>
                    <td class="limit">
                      <div><input type="text" v-model.trim="filter.call_sign" placeholder="Call Sign" /></div>
                    </td>
                    <td class="limit">
                      <div><input type="text" v-model.trim="filter.gross_tonnage" placeholder="GT" /></div>
                    </td>
                    <td class="limit">
                      <div><input type="text" v-model.trim="filter.length_overall_loa" placeholder="LOA" /></div>
                    </td>
                    <td class="limit">
                      <div><input type="text" v-model.trim="filter.breadth" placeholder="BEAM" /></div>
                    </td>
                    <td class="limit">
                      <div><input type="text" v-model.trim="filter.draught" placeholder="Draught" /></div>
                    </td>
                    <td class="tags">
                      <div><input type="text" placeholder="Tags" v-model="filter.tags" /></div>
                    </td>
                  </tr>
                </thead>
              </table>
            </div>
            <!--table body-->
            <div class="ivu-table-body ivu-table-overflowY" :style="tableBodyHeight">
              <table class="ui table shipping-table" ref="table">
                <tbody>
                  <tr
                    v-for="(item, index) in filterList"
                    :key="index"
                    class="table-row"
                    :class="{ 'row-highlight': listSelect === index }"
                    @click.stop="clickCurrentRow(item, index)"
                    :draggable="Draggable"
                    @dragstart="onDragStart(index, $event)"
                    @dragend="onDragEnd"
                  >
                    <td class="checkBox" @click.stop>
                      <div
                        class="checkGroup"
                        @click.exact="onShipClick(index)"
                        @click.stop.shift="onShipShiftClick(item, index)"
                      >
                        <Checkbox-group v-model="checkGroup" class="checkGroup" @on-change="checkGroupChange(index)">
                          <Checkbox :label="item.id">
                            <span></span>
                          </Checkbox>
                        </Checkbox-group>
                      </div>
                    </td>
                    <td class="IMO">
                      <div v-html="item.lrimo ? item.lrimo : '-'" :title="item.lrimo ? item.lrimo : '-'"></div>
                    </td>
                    <td class="IMO">
                      <div v-html="item.mmsi ? item.mmsi : '-'" :title="item.mmsi ? item.mmsi : '-'"></div>
                    </td>
                    <td class="name">
                      <div v-html="item.name ? item.name : '-'" :title="item.name ? item.name : '-'"></div>
                    </td>
                    <td class="build">
                      <div :title="item.year_of_build ? item.year_of_build : '-'">
                        {{ item.year_of_build ? item.year_of_build : "-" }}
                      </div>
                    </td>
                    <td class="limit">
                      <div :title="item.deadweight ? item.deadweight : '-'">
                        {{ item.deadweight ? item.deadweight : "-" }}
                      </div>
                    </td>
                    <td class="limit"><div v-html="item.ex_name || '-'" :title="item.ex_name || '-'"></div></td>
                    <td class="limit"><div v-html="item.call_sign || '-'" :title="item.call_sign || '-'"></div></td>
                    <td class="limit">
                      <div :title="item.gross_tonnage ? item.gross_tonnage : '-'">
                        {{ item.gross_tonnage ? item.gross_tonnage : "-" }}
                      </div>
                    </td>
                    <td class="limit">
                      <div :title="item.length_overall_loa ? item.length_overall_loa : '-'">
                        {{ item.length_overall_loa ? item.length_overall_loa : "-" }}
                      </div>
                    </td>
                    <td class="limit">
                      <div :title="item.breadth ? item.breadth : '-'">{{ item.breadth ? item.breadth : "-" }}</div>
                    </td>
                    <td class="limit">
                      <div :title="item.draught ? item.draught : '-'">{{ item.draught ? item.draught : "-" }}</div>
                    </td>
                    <td class="tags">
                      <template v-for="(i, index) in item.tags">
                        <div :key="index" v-if="index < 1" class="tag">
                          <div class="tagItem" v-html="i"></div>
                        </div>
                      </template>
                      <div class="tag" v-if="item.tags.length > 1">
                        <div class="tagItem">
                          <Icon type="ios-more"></Icon>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="tableData.length == 0 && !tableLoading">
                    <td colspan="9" style="text-align: center; height: 200px">No data found</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-------------------------aside------------------------>
        <rightAside
          v-if="asideShow"
          :asideData="selectedRow"
          ref="rightAside"
          @hide="asideShow = false"
          @refresh="changePage(1)"
        ></rightAside>
        <!-- Drag and drop image customization -->
        <div class="dragImg" ref="dragImg">
          <div class="firstImg">
            <div class="dragInfo">
              <span>{{ dragTitle }}</span>
            </div>
            <div class="badge">
              <Badge :count="checkGroup.length">
                <a href="#" class="demo-badge"></a>
              </Badge>
            </div>
          </div>
          <div class="secondImg"></div>
        </div>
      </div>
      <!-------------confirm delete folder------------>
      <Modal
        v-model="confirmShow"
        title="Delete"
        width="380"
        :styles="{ top: '238px' }"
        class="dialog"
        @on-ok="deleteFleet"
      >
        Do you want to delete this fleet?
      </Modal>
    </section>
    <section class="shipping container mapbox-container" v-if="viewMultiple">
      <div class="detail-top-btns">
        <div class="btn-groups">
          <div class="actionable-icon" @click="viewAisMultiple(false)">
            <i class="iconfont ch-icon-arrow_right backIcon"></i>
          </div>
        </div>
        <div class="btn-groups">
          <Tooltip content="In new window">
            <div class="actionable-icon" @click="openNewWindow()">
              <i class="iconfont-in-new-window"></i>
            </div>
          </Tooltip>
        </div>
      </div>
      <header class="headerTitle">
        <div class="leftSide">
          <h2>{{ userCompany }}</h2>
        </div>
        <div class="rightSide">
          <Dropdown placement="bottom-end">
            <div class="legend">
              <span class="text">Legend</span>
              <img src="/static/img/legend.png" />
            </div>
            <Dropdown-menu slot="list">
              <Dropdown-item>
                <span class="image">
                  <img class="rotate" src="/static/img/tanker.png" />
                </span>
                Tanker
              </Dropdown-item>
              <Dropdown-item>
                <span class="image">
                  <img class="rotate" src="/static/img/bulk_Carrier.png" />
                </span>
                Bulkcarrier
              </Dropdown-item>
              <Dropdown-item>
                <span class="image">
                  <img class="rotate" src="/static/img/dry.png" />
                </span>
                Dry Cargo
              </Dropdown-item>
              <Dropdown-item>
                <span class="image">
                  <img class="rotate" src="/static/img/gas.png" />
                </span>
                Gas
              </Dropdown-item>
              <Dropdown-item>
                <span class="image">
                  <img class="svg" src="/static/img/moving.svg" />
                </span>
                Moving
              </Dropdown-item>
              <Dropdown-item>
                <span class="image">
                  <img class="svg" src="/static/img/static.svg" />
                </span>
                Stationary
              </Dropdown-item>
            </Dropdown-menu>
          </Dropdown>
        </div>
      </header>
      <mapBox v-if="aisInformation && aisInformation.length > 0" :aisData="aisInformation"></mapBox>
      <span v-else class="no-data">No data found</span>
    </section>
    <chartdesk-footer>
      <span slot="left-content">{{ currentMinNum }}-{{ currentMaxNum }} of {{ totalNumSep }}</span>
    </chartdesk-footer>
    <!-------------fleet(group) details------------>
    <fleetDetail ref="fleetDetail"></fleetDetail>
    <!------------------ship detail---------------------------->
    <shipDetail ref="shipDetail" :selectedRow="selectedRow"></shipDetail>
    <!------------------saveAdvance---------------------------->
    <!--@updateGroup="updateGroup"-->
    <saveAdvance ref="saveAdvance" @updateGroup="updateGroup"></saveAdvance>
    <!-- see multiple ship position -->
  </div>
</template>

<script type="text/ecmascript-6">
import api from "../../../fetch/api.js";
import rightAside from "../aside/aside.vue";
import saveAdvance from "../saveAdvance/saveAdvance.vue";
import util from "util";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import dayjs from "dayjs";
import fleetDetail from "../fleetDetail/fleetDetail.vue";
import shipDetail from "../detail/shipDetail.vue";
import mapBox from "../detail/tab/mapbox.vue";
import utils from '@/utils'
import FEATURE_FLAG from '@/common/constants/feature-flag.constant'
var Cpackage = require("../../../../package.json");
var version = Cpackage.version;

export default {
  data() {
    return {
      //---------version
      version: "1.0.0",
      userCompany: "",
      totalNum: 0,
      pageSize: 100,
      currentPage: 1,
      currentMinNum: 1,
      currentMaxNum: 50,

      totalPages: 1,
      tableData: [],
      positionData: [],
      tableLoading: false,

      Draggable: "true",
      dragTitle: "",

      sortType: "desc",
      shipId: null,
      sPublic: false,

      flag: "all",
      view: "ship",

      isSelectAll: false,
      checkGroup: [],

      listSelect: null,
      selectedRow: {},

      sortMethod: null,
      sortField: null,

      showFilter: false,
      filter: {
        lrimo: "",
        mmsi: "",
        name: "",
        year_of_build: null,
        deadweight: null,
        ex_name: "",
        call_sign: "",
        gross_tonnage: null,
        length_overall_loa: null,
        breadth: null,
        draught: null,
        tags: ""
      },
      confirmShow: false,
      deleteLoading: false,
      asideShow: false,
      tableHeight: "",
      viewMultiple: false,
      aisInformation: [],
      lastSelectIndex: -1
    };
  },
  created() {
    this.dataInit();
    this.version = version;
    util.functionBus.starFleet = this.starFleet;
    util.functionBus.unStarFleet = this.unStarFleet;
  },
  beforeDestroy() {
    delete util.functionBus.starFleet;
    delete util.functionBus.unStarFleet;
  },
  mounted() {
    this.handleSocket();
    if (this.$route.query.detail_Id) {
      let query = {
        detail_Id: this.$route.query.detail_Id,
        id: this.$route.query.id,
        selectedPage: this.$route.query.selectedPage
      };
      if (this.$route.query.from) {
        query.from = this.$route.query.from;
      }
      this.$router.replace({
        path: "/ships/shipping",
        query: query
      });
      this.$refs.shipDetail.show();
      this.lastClickTime = null;
    }
  },
  methods: {
    ...mapActions([
      "setDraggedContacts"
    ]),
    returnIcon(type, speed) {
      let icon = "tanker";
      if (speed > 0) {
        switch (type) {
          case "Dry Cargo/Passenger":
            icon = "dry";
            break;
          case "Tankers":
            icon = "tanker";
            break;
          case "Bulk Carriers":
            icon = "bulk";
        }
      } else {
        switch (type) {
          case "Dry Cargo/Passenger":
            icon = "pulsing-dot";
            break;
          case "Tankers":
            icon = "pulsing-dot";
            break;
          case "Bulk Carriers":
          default:
            icon = "pulsing-dot";
        }
      }
      return icon;
    },

    handleSocket() {
      let socket = this.$ActionCable.subscriptions.subscriptions[0];
      let self = this;
      socket.received.tag = function(data) {
        if (self.$route.name === "shipping") {
          if (data.message[0].tagable_type === "Ship") {
            self.updateTags(data.message[0]);
          }
        }
      };
    },

    dataInit() {
      let router_name = this.$route.query.name;
      this.userCompany = router_name ? router_name : (this.userInfo.company && this.userInfo.company.name) || '';
      this.sPublic = router_name == "MarineTraffic";
      this.shipId = router_name != "MarineTraffic" ? this.$route.query.id : null;
      this.getData();
    },

    getData(KeyDown) {
      this.tableLoading = true;
      let filter = Object.assign({}, this.filter);
      for (let item in filter) {
        if (filter[item] === "" || !filter[item]) delete filter[item];
      }
      if (filter.year_of_build) {
        filter.year_of_build =
          dayjs(filter.year_of_build, "YYYY").format("YYYY") || null;
      }
      //----------------------------------search
      if (this.$route.query.search === "advance") {
        let advanceRules = [];
        //          const rules = JSON.parse(this.shipAdvance.rules);
        const rules = this.shipAdvance.rules;
        let nameContent = "";
        for (let item of rules) {
          //              ex_name/name 转换
          if (item.field === "name") {
            nameContent = item.content;
          }
          if (item.field === "ex_name" && !item.content) {
            item.content = "";
          }
          if (item.field === "ex_name" && item.content) {
            item.content = nameContent;
          }
          //            content为空不传
          if (item.show && item.content !== "") {
            let { field, content } = item;
            advanceRules.push({
              field,
              content
            });
          }
        }
        var advance = {
          q: {
            rules: JSON.stringify(advanceRules)
          }
        };
      }
      this.$nextTick(function() {
        this.viewMultiple = false;
        let params = {
          page: this.currentPage,
          per_page: this.pageSize,
          order: {},
          ship_group_id: this.shipId,
          public: this.sPublic,
          ...filter,
          type_level2: this.flag === "all" ? undefined : this.flag,
          ...advance,
          key: this.$route.query.search_key,
          me: this.$route.query.me,
          id: this.$route.query.search_id
        };
        if (this.sortMethod) {
          params.order.year_of_build = this.sortMethod;
        }
        util.getDataFromSw('ships', data => {
          this.updateUI(data, KeyDown);
        });
        api
          .get_shipping(params)
          .then(res => {
            this.updateUI(res, KeyDown);
          })
          .catch(error => {
            console.log(error);
            this.tableLoading = false;
          });
      });
    },
    updateUI(res, KeyDown) {
      const check_ids = this.checkGroup.slice(0);
      this.checkGroup = [];
      if (res.ships.length !== 0) {
        this.tableData = res.ships.map(ship => utils.sanitizeObject(ship));
        this.totalNum = res.total;
        this.currentPage = res.page;
        this.currentMaxNum =
          this.currentPage * this.pageSize > res.total
            ? res.total
            : this.currentPage * this.pageSize;
        this.currentMinNum =
          this.currentPage * this.pageSize - (this.pageSize - 1);
        this.totalPages = parseInt(
          (this.totalNum + this.pageSize - 1) / this.pageSize
        );
        if (!KeyDown) {
          this.asideShow = false;
        }
        //-------------------------------------search带id
        if (this.$route.query.search_id) {
          let index = this.tableData.findIndex(item => {
            return item.id == this.$route.query.search_id;
          });
          // if (index !== -1) {
          //   this.clickCurrentRow(this.tableData[index], index);
          // }
        }
        //--------------------------------------up/down 跳转页面
        if (KeyDown === "down") {
          this.selectedRow = this.tableData[0];
        } else if (KeyDown === "up") {
          this.selectedRow = this.tableData[49];
        }
      } else {
        this.tableData = [];
        this.currentPage = 1;
        this.currentMinNum = 0;
        this.currentMaxNum = 0;
        this.totalNum = res.total;
      }
      this.isSelectAll = false;
      //          this.checkGroup = [];
      this.tableLoading = false;
      this.$nextTick(() => {
        this.calcWrapperHeight();
        let obj = this.$el.querySelector(".ivu-table-body");
        obj.scrollTop = 0;
        //            请求完成后 对checkGroup赋值
        this.checkGroup = check_ids;
      });
    },
    //      状态调为初始
    argInit() {
      this.totalNum = 0;
      this.listSelect = null;
      this.isSelectAll = false;
      this.checkGroup = [];
      this.currentMinNum = 1;
      this.currentMaxNum = 50;
      this.sPublic = false;
      this.$refs.shipDetail.hide();
    },
    //--------------------------多选框
    selectAll() {
      this.isSelectAll = !this.isSelectAll;
      if (this.isSelectAll) {
        //          this.checkGroup = this.tableData.map(item => item.id);
        let checked_ids = new Set(this.checkGroup);
        this.tableData.map(item => checked_ids.add(item.id));
        this.checkGroup = Array.from(checked_ids);
      } else {
        //          this.checkGroup = [];
        this.tableData.map(item => this.checkGroup.remove(item.id));
      }
      if (this.tableData.length > 0) {
        this.dragTitle = util.sanitizeHtml(this.tableData[0].name);
      }
    },
    clearCheck() {
      this.isSelectAll = false;
      this.checkGroup = [];
    },
    //--------------------------分页显示
    changePage(val, KeyDown) {
      this.currentPage = val;
      this.getData(KeyDown);
    },
    //      跳转到first page/last page
    skipPage(val) {
      if (val === 1 && this.currentPage !== 1) {
        this.currentPage = 1;
        this.getData();
      }
      if (val === 2 && this.currentPage !== this.totalPages) {
        this.currentPage = this.totalPages;
        this.getData();
      }
    },
    //      改变pageSize
    sizeChange(value) {
      this.pageSize = parseInt(value);
      this.currentPage = 1;
      this.getData();
    },
    select_Up_Down() {
      let _this = this;
      let s = this.tableData.length;
      //选中行的坐标
      if (this.$el.querySelector(".row-highlight")) {
        var row_y = this.$el
          .querySelector(".row-highlight")
          .getBoundingClientRect().bottom;
      }
      window.onkeydown = function(e) {
        if (
          e.target.className === "ql-editor" ||
          e.target.localName === "input" ||
          e.target.className === "new-tag" ||
          e.target.className === "ivu-input" ||
          e.target.className === "atwhoinput_editor"
        ) {
          return;
        }
        //----------down
        if (e.keyCode === 40 || e.keyCode === 32) {
          e.preventDefault();
          _this.listSelect++;
          let obj = _this.$el.querySelector(".ivu-table-body");
          if (row_y > document.body.clientHeight - 56) {
            obj.scrollTop = obj.scrollTop + (row_y - 200);
          }
          if (_this.listSelect === s) {
            //选中底部跳转下一页 不关闭 aside
            if (_this.currentPage < _this.totalPages) {
              let pageCount = _this.currentPage + 1;
              _this.changePage(pageCount, "down");
              obj.scrollTop = 0;
              _this.listSelect = 0;
              _this.showPreview = true;
              return;
            }
            obj.scrollTop = 0;
            _this.listSelect = 0;
          }
        }
        //-----------up
        if (e.keyCode === 38) {
          e.preventDefault();
          _this.listSelect--;
          let obj = _this.$el.querySelector(".ivu-table-body");
          if (row_y < document.body.clientHeight - 370) {
            obj.scrollTop = obj.scrollTop - (row_y + 120);
          }
          if (_this.listSelect === -1) {
            if (_this.currentPage > 1) {
              let pageCount = _this.currentPage - 1;
              _this.changePage(pageCount, "up");
              obj.scrollTop = 10000;
              _this.listSelect = this.pageSize - 1; //原49
              _this.showPreview = true;
              return;
            }
            _this.$el.scrollTop = 0;
            _this.listSelect = 0;
          }
        }
        //-----------改变aside的数据
        _this.selectedRow = _this.tableData[_this.listSelect];
      };
    },
    //--------------------------点击展开
    clickCurrentRow(rows, index) {
      let now = new Date().getTime();
      if (this.listSelect !== index) {
        this.listSelect = index;
      }
      this.selectedRow = rows;
      // for double click
      if (
        this.selectedRow === rows &&
        this.lastClickTime &&
        now - this.lastClickTime < 500
      ) {
        this.asideShow = false
        clearTimeout(this.clickTimer);
        this.clickTimer = null;
        const query = Object.assign({}, this.$route.query);
        if (!query.detail_Id) {
          query.detail_Id = this.selectedRow.id;
        }
        this.$router.replace({
          path: "/ships/shipping",
          query: query
        });
        this.$refs.shipDetail.show();
        this.lastClickTime = null;
      } else {
        this.clickTimer = setTimeout(() => {
          for (let item in this.selectedRow) {
            if (
              !this.selectedRow[item] &&
              item !== "tags" &&
              item !== "public"
            ) {
              this.selectedRow[item] = "-";
            }
          }
          this.asideShow = true;
          this.lastClickTime = null;
        }, 500);
      }
      this.lastClickTime = now;
    },
    // sort part
    toggleSort(filed) {
      // change sort type
      if (!this.sortField) this.sortField = "build_year";
      const oldType = this.sortField;
      if (this.sortMethod === "asc") {
        this.sortMethod = "desc";
      } else {
        this.sortMethod = "asc";
      }
      // 如果点击了新的一列，sort为desc
      if (oldType !== filed) {
        this.sortMethod = "desc";
      }
      this.sortField = filed;
      this.currentPage = 1;
      this.getData();
    },
    // filter part
    toggleFilter() {
      if (this.showFilter === true) {
        this.filter = {
          lrimo: "",
          name: "",
          year_of_build: null,
          deadweight: null,
          ex_name: "",
          call_sign: "",
          gross_tonnage: null,
          length_overall_loa: null,
          breadth: null,
          draught: null,
          tags: ""
        };
        this.currentPage = 1;
        this.getData();
      }
      this.showFilter = !this.showFilter;
    },
    clearDate() {
      this.filter.year_of_build = null;
      this.enterFilter();
    },
    enterFilter(date) {
      if (date) {
        this.filter.year_of_build = date;
      }
      this.currentPage = 1;
      this.getData();
    },
    //-------------------------------------fleet star
    starFleet() {
      const params = {
        starable_id: parseInt(this.$route.query.id),
        starable_type: "ShipGroup"
      };
      api
        .star_folder(params)
        .then(res => {
          this.$emit("refreshGroup");
          const query = this.$route.query;
          this.$router.replace(
            `/ships/shipping?name=${encodeURIComponent(query.name)}&id=${query.id}&star=true`
          );
        })
        .catch(error => {
          console.log(error);
        });
    },
    unStarFleet() {
      const params = {
        starable_id: parseInt(this.$route.query.id),
        starable_type: "ShipGroup"
      };
      api
        .unStar_folder(params)
        .then(res => {
          this.$emit("refreshGroup");
          const query = this.$route.query;
          this.$router.replace(
            `/ships/shipping?name=${encodeURIComponent(query.name)}&id=${query.id}&star=false`
          );
        })
        .catch(error => {
          console.log(error);
        });
    },
    //--------------------------拖拽
    onDragStart(index, e) {
      if (this.checkGroup.length === 0 || this.checkGroup.length === 1) {
        let dragEl = e.target.cells[3];
        dragEl && (dragEl.style.paddingLeft = '8px');
        e.dataTransfer.setDragImage(dragEl, 10, 10);
        this.setDraggedContacts([this.tableData[index]]);
      } else {
        let dragEl = this.$refs.dragImg;
        dragEl.style.display = 'block';
        dragEl.style.top = e.clientY;
        dragEl.style.left = e.clientX;
        e.dataTransfer.setDragImage(this.$refs.dragImg, 10, 10);
        const selectedShips = this.tableData.filter(ship => {
          return this.checkGroup.includes(ship.id)
        })
        this.setDraggedContacts(selectedShips);
      }
    },
    onDragEnd() {
      this.setDraggedContacts([]);
      return false;
    },
    //-----------------------------handel setting
    removeShipsFromGroup() {
      if (this.checkGroup.length === 0) return;
      api
        .delete_ship_from_group({
          ship_ids: this.checkGroup,
          ship_group_id: this.$route.query.id
        })
        .then(res => {
          this.$Message.success(
            `Deleted ${this.checkGroup.length} ships from fleet success`
          );
          this.getData();
        });
    },
    editFleet() {
      let id = this.$route.query.id.toString();
      this.$emit("editGroup", id);
    },
    deleteFleet() {
      this.deleteLoading = true;
      let id = this.$route.query.id;
      api
        .delete_ship_group(id)
        .then(res => {
          this.confirmShow = false;
          this.deleteLoading = false;
          this.$emit("refreshGroup");
          this.$router.push("/ships/shipping");
          this.$Message.success(`Deleted fleet successfully`);
        })
        .catch(error => {
          console.log(error);
          this.deleteLoading = false;
        });
    },
    showFleetDetail() {
      this.$refs.fleetDetail.show(this.$route.query.id);
    },
    //-------多选框触发
    checkGroupChange(index) {
      this.dragTitle = util.sanitizeHtml(this.tableData[index].name);
    },
    optionChange(value) {
      this.flag = value;
      this.currentPage = 1;
      this.getData();
    },
    viewChange(value) {
      if (value === "position") {
        const query = Object.assign({}, this.$route.query);
        //          this.$router.push('/ships/positionView');
        this.$router.replace({
          name: "positionView",
          query: query
        });
      }
    },
    // 计算wrapper 高度
    calcWrapperHeight() {
      if (!this.$refs.table || !this.$refs.wrapper) {
        return;
      }
      if (
        this.$refs.table.clientHeight >
        this.$refs.wrapper.clientHeight - 41
      ) {
        this.tableHeight = "height: 100%";
      } else {
        this.tableHeight = "";
      }
    },
    updateTags(tag) {
      if(!tag)
        return;
      this.tableData.forEach(item => {
        if (item.id !== tag.tagable_id)
          return;
        const index = item.tags.findIndex(name => name == tag.name);
        !tag.deleted_at && index == -1 && item.tags.push(tag.name);
        tag.deleted_at && index > -1 && item.tags.splice(index, 1);
      });
    },
    editAdvance() {
      this.$emit("editAdvance", "edit");
    },
    openSaveAdvance() {
      this.$refs.saveAdvance.show();
    },
    updateGroup() {
      //->nav(group)
      this.$emit("refreshGroup");
    },
    gotoDetail() {
      console.log("details", this.checkGroup);
    },
    viewAisMultiple(value) {
      if(this.checkGroup.length == 0)
        return this.$Message.info("Select ship to view AIS");

      value && this.getAISData();
      !value && (this.viewMultiple = false)
    },
    //get Api data
    getAISData() {
      this.aisInformation = [];
      // this.checkGroup.forEach(o => {
      var values = this.checkGroup;
      if (this.checkGroup.length > 100) {
        values = this.checkGroup.slice(0, 100);
        this.$Message.info("Only top 100 ship will be displayed");
      }
      let param = values.join(",");
      this.tableLoading = true;
      util.getDataFromSw('ais-information', data => {
        this.updateUIAIS(data)
      })
      api
        .get_all_ship({id: param, source: this.$route.query.source})
        .then(res => {
          this.updateUIAIS(res);
        })
        .catch(error => {
          this.tableLoading = false;
          console.log(error);
        });
    },
    updateUIAIS(res) {
      if (res && res.ais_information && res.ais_information.length > 0) {
        this.aisInformation = [];
        res.ais_information.map(o => {
          let value = {
            type: "Feature",
            properties: {},
            geometry: {
              type: "Point",
              coordinates: [o.lon, o.lat]
            }
          };
          value.properties = Object.assign(value.properties, o);
          value.properties.icon = this.returnIcon(
            value.properties.type_level2,
            value.properties.speed
          );
          this.aisInformation.push(value);
        });
      }
      this.tableLoading = false;
      this.viewMultiple = true;
    },
    openNewWindow() {
      let id = this.checkGroup.join(",");
      if (this.checkGroup.length > 20) {
        let data = this.checkGroup.slice(0, 20);
        id = data.join(",");
      }
      const url = `allShip/${id}/${encodeURIComponent(this.userCompany)}`;
      util.openWindow(url);
    },
    onShipShiftClick(ship, index){
      if(this.lastSelectIndex == -1){
        this.lastSelectIndex = index;
        return;
      }

      const selected = this.checkGroup.findIndex(e => e == ship.id) >= 0;
      const minIndex = this.lastSelectIndex < index ? this.lastSelectIndex : index;
      const maxIndex = this.lastSelectIndex < index ? index : this.lastSelectIndex;

      for(let i = minIndex; i <= maxIndex; i++){
        const shipId = this.filterList[i].id;
        const existedIndex = this.checkGroup.findIndex(e => e == shipId);
        !selected && existedIndex == -1 && this.checkGroup.push(shipId);
        selected && existedIndex >= 0 && this.checkGroup.splice(existedIndex, 1);
      }
      this.lastSelectIndex = index;
    },
    onShipClick(index) {
      this.lastSelectIndex = index
    }
  },
  computed: {
    ...mapGetters([
      "userInfo",
      "shipAdvance",
      "shipBasic"
    ]),
    isEnablePositionView() {
      return !this.$growthBook.isOn(FEATURE_FLAG.POSITION_VIEW)
    },
    p_edit_group() {
      const personal = !!this.$route.query.personal;
      if (personal) return personal;
      return this.checkPermission("add_and_edit_ships_in_company_directory");
    },
    p_delete_group() {
      const personal = !!this.$route.query.personal;
      if (personal) return personal;
      return this.checkPermission("delete_ships_in_company_directory");
    },
    //-------------------------------------权限
    //---------操作ship group权限
    tableBodyHeight() {
      if (this.tableData.length > 8) {
        if (this.showFilter) {
          return "height:calc(100% - 89px)";
        } else {
          return "height:calc(100% - 41px)";
        }
      }
    },
    //---------------------------------table data(搜索高亮显示)
    filterList() {
      //        return this.tableData;
      if (this.$route.query.search_key) {
        return this.tableData.map(item => {
          let word = this.$route.query.search_key.toString();
          word = word.replace("+", "");
          word = word.replace("-", "");
          word = word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // escape special characters
          let validation = new RegExp("(" + word + ")", "i");
          item.lrimo = item.lrimo.toString();
          let name = item.name;
          let replaceName = validation.exec(name);
          let lrimo = item.lrimo;
          let replaceLrimo = validation.exec(lrimo);
          let ex_name = item.ex_name;
          let replaceEx_name = validation.exec(ex_name);
          let call_sign = item.call_sign;
          let replaceCall_sign = validation.exec(call_sign);
          let strTags = item.tags.join(",");
          let replaceTags = validation.exec(strTags);
          if (replaceName) {
            item.name = name.replace(
              new RegExp("(" + word + ")", "ig"),
              "<span style='background: #ffff01; color:#414141'>" + replaceName[0] + "</span>"
            );
          }
          if (replaceLrimo) {
            item.lrimo = lrimo.replace(
              new RegExp("(" + word + ")", "ig"),
              "<span style='background: #ffff01; color:#414141'>" + replaceLrimo[0] + "</span>"
            );
          }
          if (replaceEx_name) {
            item.ex_name = ex_name.replace(
              new RegExp("(" + word + ")", "ig"),
              "<span style='background: #ffff01; color:#414141'>" +
                replaceEx_name[0] +
                "</span>"
            );
          }
          if (replaceCall_sign) {
            item.call_sign = call_sign.replace(
              new RegExp("(" + word + ")", "ig"),
              "<span style='background: #ffff01; color:#414141'>" +
                replaceCall_sign[0] +
                "</span>"
            );
          }
          if (replaceTags) {
            strTags = strTags.replace(
              new RegExp("(" + word + ")", "ig"),
              "<span style='background: #ffff01; color:#414141'>" + replaceTags[0] + "</span>"
            );
            item.tags = strTags.split(",");
          }
          return item;
        });
      } else {
        return this.tableData;
      }
    },
    //      总数添加千分号
    totalNumSep() {
      let parts = this.totalNum.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    }
  },
  updated() {
    this.select_Up_Down();
  },
  watch: {
    $route(to, from) {
      if (this.$route.query.detail_Id)
        return;

      this.argInit();
      this.filter = {
        lrimo: "",
        name: "",
        year_of_build: null,
        deadweight: null,
        ex_name: "",
        call_sign: "",
        gross_tonnage: null,
        length_overall_loa: null,
        breadth: null,
        draught: null,
        tags: ""
      };
      this.sortMethod = null;
      this.sortField = null;
      this.showFilter = false;
      this.flag = "all";
      if (this.$route.query.name) {
        this.tableLoading = true;
        this.userCompany = this.$route.query.name;
        if (this.$route.query.id) {
          const sId = this.$route.query.id;
          this.shipId = sId;
        } else {
          this.sPublic = true;
          this.shipId = null;
        }
      } else {
        this.userCompany = (this.userInfo.company && this.userInfo.company.name) || '';
        this.shipId = null;
        this.sPublic = false;
      }
      this.currentPage = 1;
      this.getData();
    }
  },
  components: {
    saveAdvance,
    rightAside,
    shipDetail,
    fleetDetail,
    mapBox //groupDetail
    // "view-multiple": viewMultiple
  }
};
</script>

<style lang="scss" scoped>
/**/
.main-content-wrapper {
  flex: 1 0;
  overflow-x: hidden;
  overflow-y: auto;
  overflow-y: overlay;
  position: relative;
}
.headerTitle {
  background-color: var(--background-color);
}
.unActive {
  color: var(--text-color-disable) !important;
  cursor: not-allowed;
}
.container-wrapper {
  padding-bottom: 0;
  display: flex;
  flex-direction: column;

  .separateLine {
    display: inline-block;
    width: 1px;
    height: 20px;
    background: #d8e2e7;
    margin: 0 19px;
  }
  .shipping {
    overflow: hidden;
    flex: 1 0;
    .viewSelect {
      margin-right: 16px;
    }
    .contain-wrapper {
      min-height: 400px;
      .shipping-table {
        table-layout: fixed;
        tr {
          cursor: pointer;
        }
        th {
          width: 10%;
          padding-right: 10px;
          &:not(:first-child) {
            padding-left: 0;
          }
          &.checkBox {
            width: 4%;
            min-width: 50px;
            text-overflow: clip;
          }
          &.IMO {
            width: 7.5%;
          }
          &.name {
            width: 13%;
          }
          &.build {
            width: 7%;
          }
          &.limit {
            width: 7.5%;
          }
          &.tags {
            width: 16%;
            .tags-cell {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .ch-icon-filter {
                cursor: pointer;
                vertical-align: middle;
              }
              .clearFilter {
                cursor: pointer;
                color: var(--primary-color);
                text-decoration: underline;
              }
            }
          }
          .ivu-icon {
            opacity: 0.2;
          }
          .active {
            .ivu-icon {
              color: var(--primary-color);
              opacity: 1;
            }
          }
        }
        td {
          width: 10%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding-right: 10px;
          border-bottom: 1px solid var(--lighter-border-color);
          & > div {
            text-overflow: ellipsis;
            overflow: hidden;
          }
          &:not(:first-child) {
            padding-left: 0;
          }
          &.checkBox {
            width: 4%;
            min-width: 50px;
            position: relative;
            white-space: normal;
          }
          &.IMO {
            width: 7.5%;
          }
          &.name {
            width: 13%;
          }
          &.limit {
            width: 7.5%;
          }
          &.build {
            width: 7%;
          }
          &.tags {
            width: 16%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: clip;
            .tag {
              display: inline-flex;
              max-width: 75%;
              padding-right: 11px;
              &:nth-child(2) {
                width: 25%;
                padding-right: 0;
                .tagItem {
                  text-overflow: unset;
                }
              }
            }
            .tagItem {
              display: inline-block;
              border: 1px solid var(--border-color);
              padding: 0 8px;
              height: 24px;
              line-height: 22px;
              border-radius: 20px;
              max-width: 100%;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              background-color: var(--component-color);
              color: var(--on-component-color);
              text-align: center;
              cursor: pointer;
              .ivu-icon-more {
                line-height: 22px;
                vertical-align: middle;
              }
            }
          }
        }
      }
    }
  }
  .shipping.container {
    flex: 1;
    min-height: 0;
    min-width: 0;
  }
}
.image {
  width: 20px;
  padding-top: 5px;
  .rotate {
    transform: rotate(90deg);
  }
  .svg {
    margin-bottom: 5px;
    margin-left: -6px;
  }
}
.ivu-dropdown-item {
  outline: none;
}
// for overflow of datepicker and selector of filterHead
.filterHeads {
  background: #ffffff;
  td {
    border-top: dashed 1px rgba(20, 40, 74, 0.4) !important;
    border-bottom: dashed 1px rgba(20, 40, 74, 0.4) !important;
    &:first-child {
      border-left: dashed 1px rgba(20, 40, 74, 0.4);
    }
    &:last-child {
      border-right: dashed 1px rgba(20, 40, 74, 0.4);
    }
  }
  input {
    width: 80px;
    outline: none;
    border: none;
    background: transparent;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    color: #f00 !important;
    font-weight: normal;

    &::placeholder {
      font-weight: normal;
      font-style: italic;
      color: #f00 !important;
      opacity: 0.8 !important;
    }
  }
}
.ui.table thead th.clickable {
  cursor: pointer;
}
.ui.table thead th.bold {
  font-weight: bold;
}
.legend {
  cursor: pointer;
  display: flex;
  .text {
    line-height: 14px;
    padding-right: 10px;
  }
}
.ivu-dropdown-item {
  display: flex;
  line-height: 30px;
  justify-content: left;
}
</style>

<style lang="scss">
.Ship_AIS {
  border-left: 1px solid var(--border-color);
  padding-left: 10px;
  .ivu-tooltip-rel {
    top: 2px;
  }
  i {
    color: var(--text-color-disable);
    &.active {
      color: var(--text-color);
    }
  }
}
.mapbox-container {
  .detail-top-btns {
    display: flex;
    padding: 8px;
    border-bottom: 1px solid var(--border-color);
    .btn-groups {
      margin-right: 8px;
      .iconfont {
        font-size: 18px;
      }
      [class^="iconfont-"] {
        font-size: 20px;
      }
    }
  }
  .no-data {
    text-align: center;
    padding: 16px;
  }
}
.padding-none {
  padding: 0;
}
.filterHeads {
  .filter-year {
    .ivu-input-suffix {
      display: none;
    }
    .ivu-input {
      border: none;
      &::placeholder {
        font-weight: normal;
        font-style: italic;
        color: #f00 !important;
        opacity: 0.8 !important;
      }
    }
  }
}
.shipping {
  .select-no-border {
    width: auto;
  }
}
</style>
